import React, { useEffect, useState } from "react";
import styled from "styled-components";
import classnames from "classnames";
import { Link } from "gatsby";
import Bold from "../Typography/Bold";
import moment from "moment";

// typings
import Appointment from "../../utils/interfaces/Appointment";

// components
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import ColoredTableCell from "../../components/Tables/ColoredTableCell";
import Italic from "../../components/Typography/Italic";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const TableWrapper = styled.div`
  margin: 0rem 6rem 6rem;
`;

const InfoTableCell = styled(TableCell)`
  position: relative;

  &.conflicting {
    &:after {
      content: "Conflicto de Horario";
      position: absolute;
      top: 0px;
      right: 0px;
      content: "";
      height: 100%;
      width: 40px;
      background-color: red;
    }
  }
  .MuiSvgIcon-root {
    height: 0.875rem;
    width: 0.875rem;
    margin: 0rem 1rem;
  }

  &:hover {
    background-color: whitesmoke;
    cursor: pointer;
  }
`;

moment.locale("es");

interface DashboardGridProps {
  appointments: Appointment[];
  totalRows: number;
  currentPage: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
}

const DashboardGrid = (props: DashboardGridProps) => {
  const [appointments, setAppoinments] = useState<Appointment[]>([]);

  const displayTableRows = (): Array<JSX.Element> => {
    return appointments.map((appointment: Appointment) => {
      const patientRut = appointment.patient.document_number;
      return (
        <TableRow key={appointment.id}>
          <Link
            to={`/medical-orders/${appointment.id}/`}
            style={{ textDecoration: "none" }}
          >
            <InfoTableCell
              className={classnames({ conflicting: appointment.time_conflict })}
            >
              <Bold>
                {appointment.patient?.fullname || "Sin paciente asignado"}
              </Bold>
              <br />
              <Bold>
                {patientRut}
              </Bold> <ArrowRightAltIcon fontSize="small" />{" "}
              {moment(appointment.local_begin_date).format("LLL")}
              <br />
              {appointment.target_address}
              <br />
              {appointment.service_names}
            </InfoTableCell>
          </Link>

          <InfoTableCell align="center">
            {appointment.block_taken ? "Sí" : "No"}
          </InfoTableCell>
          <InfoTableCell
            align="center"
            color={
              appointment.status === "scheduled" ? "incomplete" : "completed"
            }
          >
            {appointment.status === "scheduled" ? "No" : "Sí"}
          </InfoTableCell>
          <ColoredTableCell
            align="center"
            color={appointment.finished ? "completed" : "incomplete"}
          >
            {appointment.finished ? "Sí" : "No"}
          </ColoredTableCell>
        </TableRow>
      );
    });
  };

  useEffect(() => {
    setAppoinments(props.appointments);
  }, [props.appointments]);

  return (
    <TableWrapper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Italic>Appointment</Italic>
              </TableCell>
              <TableCell align="center">Horario tomado</TableCell>
              <TableCell align="center">Confirmado</TableCell>
              <TableCell align="center">
                <Italic>Finished</Italic>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{displayTableRows()}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={props.totalRows}
                rowsPerPage={20}
                rowsPerPageOptions={[20]}
                page={props.currentPage}
                onPageChange={props.handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </TableWrapper>
  );
};

export default DashboardGrid;
