import React, { useState, useEffect, useContext } from "react";

import styled from "styled-components";
import api from "../../utils/api/api";
import appointmentService from "../../utils/api/v1/appointmentService";
import * as moment from "moment";
import { isEmpty } from "lodash";
import { useQueryParam, StringParam } from "use-query-params";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";
import * as regionComuna from "../../utils/comunas/region_comunas.json";
import * as ciudadColonia from "../../utils/comunas/ciudad_colonia.json";
import { translateRegion } from "../../utils/comunas/regionTranslator";

// Contexts
import { FiltersContext } from "../../components/Stores/FilterStore";

// components
import PrivateRoute from "../../components/Authentication/PrivateRoute";
import Flex from "../../components/Containers/Flex";
import Italic from "../../components/Typography/Italic";
import LoadingError from "../../components/Loaders/LoadingError";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// interfaces
import Appointment from "../../utils/interfaces/Appointment";
import MedicalOrderGrid from "../../components/Appointments/MedicalOrderGrid";

// other files
import { COUNTRIES } from "../../utils/data/constants";
import UploadMedicalOrderRequest from "../../components/Appointments/UploadMedicalOrderRequest";

const Header = styled.div`
  max-width: 1400px;
  padding: 0 3rem 2rem 3rem;
  margin: 0 auto;
`;

const PrimaryButton = styled(Button)`
  max-height: 2.5rem;
  border-color: var(--primary);
  color: var(--primary);
`;

const Spaced = styled.div`
  margin: 0.5rem 1rem;
`;

const CommuneSelect = styled(Select)`
  width: 200px;
  margin-bottom: 1rem;
`;

const CountrySelect = styled(Select)`
  width: 200px;
  margin-bottom: 1rem;
`;

const RegionSelect = styled(Select)`
  width: 250px;
  margin-bottom: 1rem;
`;

const ExamTypeSelect = styled(Select)`
  width: 150px;
`;

moment.locale("es");

interface AppointmentRequest {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Appointment[];
  };
}

const locationSelectProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const IndexPage = (props): JSX.Element => {
  const [error, setError] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<Array<Appointment>>([]);
  // @ts-ignore
  const [filters, filtersDispatch] = useContext(FiltersContext);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [search, setSearch] = useQueryParam("search", StringParam);

  const statusMap = {
    "Sin confirmar": "scheduled",
    Confirmado: "confirmed",
  };

  const fetchAppointments = async (): Promise<void> => {
    setLoading(true);
    try {
      const request: AppointmentRequest = await api.request({
        method: "get",
        url: "dashboard/appointments/receipts_list/",
        params: {
          begin_date: filters.initDate.format("YYYY-MM-DD"),
          end_date: filters.finishDate.format("YYYY-MM-DD"),
          nurse: filters.nurse,
          booked: filters.booked,
          contacted: filters.contacted,
          visited: filters.visited,
          "receipt-sent": filters.receipt,
          "results-sent": filters.results,
          sales_source: filters.salesSource,
          country: filters.country,
          comuna: filters.comuna,
          region: translateRegion(filters.region),
          exam_type: filters.exam_type,
          status: statusMap[filters.status] || filters.status,
          section_origin: "medical-order",
        },
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const fetchAppointmentsBySearch = async () => {
    setLoading(true);
    try {
      const req = await appointmentService.searchAppointments(search);
      setAppointments(req.data.results);
      setTotalRows(req.data.count);
      setNextRequest(req.data.next.replace("http://", "https://"));
      setPrevRequest(req.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const handleFilterButton = (): void => {
    fetchAppointments();
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "GET",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  const createCommuneFilter = (): JSX.Element => {
    const regiones = Object.keys(regionComuna);
    let comunas =
      regionComuna[filters?.region === "Todas" ? regiones[0] : filters?.region];
    if (filters?.country === "México") {
      comunas = ciudadColonia[filters?.region];
    }
    const label = filters?.country === "Chile" ? "Comuna" : "Colonia";
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>{label}</InputLabel>
          <CommuneSelect
            autoWidth
            value={filters?.comuna}
            disabled={filters?.region === "Todas"}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_COMMUNE",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            <MenuItem value={"Todas"}>Todas</MenuItem>
            {comunas?.map((commune: string) => (
              <MenuItem value={commune} key={commune}>
                {commune}
              </MenuItem>
            ))}
          </CommuneSelect>
        </FormControl>
      </Spaced>
    );
  };

  const createCountryFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>País</InputLabel>
          <CountrySelect
            autoWidth
            value={filters?.country}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_COUNTRY",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            {COUNTRIES.map((country: string) => (
              <MenuItem value={country} key={country}>
                {country}
              </MenuItem>
            ))}
          </CountrySelect>
        </FormControl>
      </Spaced>
    );
  };

  const createRegionFilter = (): JSX.Element => {
    let regions = [];
    if (filters?.country === "Chile") {
      regions = Object.keys(regionComuna).slice(0, -1); // Remove default key
    } else if (filters?.country === "México") {
      regions = ["CDMX"];
    }
    const label = filters?.country === "Chile" ? "Región" : "Ciudad";
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>{label}</InputLabel>
          <RegionSelect
            autoWidth
            value={filters?.region}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_REGION",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            <MenuItem value={"Todas"}>Todas</MenuItem>
            {regions.map((region: string) => (
              <MenuItem value={region} key={region}>
                {translateRegion(region)}
              </MenuItem>
            ))}
          </RegionSelect>
        </FormControl>
      </Spaced>
    );
  };

  const createStatusFilter = (): JSX.Element => {
    return (
      <Spaced>
        <FormControl margin="normal">
          <InputLabel>Status</InputLabel>
          <ExamTypeSelect
            autoWidth
            value={filters?.status}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_STATUS",
                payload: e.target.value as string,
              });
            }}
          >
            <MenuItem value={"Todos"}>Todos</MenuItem>
            <MenuItem value={"Confirmado"}>Confirmado</MenuItem>
            <MenuItem value={"Sin confirmar"}>Sin confirmar</MenuItem>
          </ExamTypeSelect>
        </FormControl>
      </Spaced>
    );
  };

  useEffect((): void => {
    if (!search) {
      fetchAppointments();
    }
  }, []);

  useEffect(() => {
    if (search) {
      fetchAppointmentsBySearch();
    }
  }, [search]);

  return (
    <PrivateRoute>
      <LoadingError error={error} loading={loading} />
      {!loading && isEmpty(error) && (
        <>
          <Header>
            <Flex direction="column" align="center">
              <h1>
                Estado de <Italic>Appointments de Órdenes médicas</Italic>
              </h1>
              <Flex align="center" justify="center" wrap="wrap">
                <Spaced>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha inicio"
                      value={filters?.initDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_INITDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Spaced>
                <Spaced>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha término"
                      value={filters?.finishDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_FINISHDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Spaced>
                {createStatusFilter()}
              </Flex>
              <Flex align="center" justify="center" wrap="wrap">
                {createCountryFilter()}
                {createRegionFilter()}
                {createCommuneFilter()}
                <Spaced>
                  <PrimaryButton
                    variant="outlined"
                    color="primary"
                    onClick={handleFilterButton}
                  >
                    Filtrar
                  </PrimaryButton>
                </Spaced>
              </Flex>
            </Flex>
          </Header>
          <Flex
            direction="column"
            justify="right"
            align="flex-start"
            margin="2rem 6rem"
          >
            <UploadMedicalOrderRequest />
          </Flex>
          <MedicalOrderGrid
            appointments={appointments}
            totalRows={totalRows}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;
